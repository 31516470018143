<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fontAwesomeIcon?: string;
    fontAwesomeIconSize?: number;
    iconUrl?: string;
    iconUrlActive?: string;
    indentPixels: number;
  }>(),
  {
    fontAwesomeIconSize: 16,
  }
);
</script>
<template>
  <div
    class="navigation-item-icon-container"
    :style="{
      'margin-left': `${props.indentPixels}px`,
    }"
  >
    <template v-if="props.fontAwesomeIcon">
      <span
        :class="['fa-light', `fa-${props.fontAwesomeIcon}`]"
        :style="{
          'font-size': `${props.fontAwesomeIconSize}px`,
        }"
      />
      <span
        :class="['fa-regular', `fa-${props.fontAwesomeIcon}`]"
        :style="{
          'font-size': `${props.fontAwesomeIconSize}px`,
        }"
      />
    </template>
    <template v-else-if="props.iconUrl">
      <img :src="props.iconUrl" :style="{}" />
      <img :src="props.iconUrlActive || props.iconUrl" :style="{}" />
    </template>
  </div>
</template>
<style lang="scss">
.navigation-item-icon-container {
  margin-right: 10px;
  text-align: center;

  > img,
  > span {
    width: 21px;
    min-width: 21px;
  }
}
</style>
