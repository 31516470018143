<script setup lang="ts">
import { computed } from 'vue';

import { navigateTo } from '@mfl/framework';

import { currentRoute } from './current-route';
import { GroupedItems } from './navigation-items.types';
import { NavigationBarMode, NavigationItem } from '../api.types';
import { navigationBarMode } from '../api';
import { normalizeOrnaments } from './ornaments';
import NavigationGroup from './navigation-group.vue';
import NavigationItemIcon from './navigation-item-icon.vue';

const props = defineProps<{
  item: NavigationItem;
  groups: GroupedItems;
  level: number;
}>();

const isMinimized = computed(
  () => navigationBarMode.value === NavigationBarMode.MinimalView
);

/**
 * Calculate the amount of level indentation for subitems
 *
 * figma design did not take minimized mode into account\
 * because there is no such use-case - the bar should be in `locked` when there are subitems\
 * We did do.. something - reduce the indentation to 5px per level when in min mode
 */
const indentPixels = computed(() => props.level * (isMinimized.value ? 5 : 15));

/**
 * Ornaments
 *
 * TODO: Support the props&events the Ornaments API promised!
 */
const ornaments = computed(() => {
  return normalizeOrnaments(props.item);
});

/** Should the item be rendered as the selected active item? */
const isActive = computed(() => {
  const route = props.item.route;
  if (!route) return false;

  // Item itself is on the current route (not a child)
  return currentRoute.value.endsWith(route);
});

/**
 * Should the item's subitems be displayed?
 */
const isExpanded = computed(() => {
  if (isActive.value) return true;

  // Warning: recursion ahead
  function hasActiveSubItem(key: string): boolean {
    // Has subitems
    const subItems = props.groups.get(key);

    if (!subItems?.length) return false;

    // One of the subitems is active
    const activeSubItem = subItems.find(
      (i) => i.route && currentRoute.value.endsWith(i.route)
    );

    if (activeSubItem) return true;

    // Dive deeper
    const deepActiveSubItem = subItems.find((i) => hasActiveSubItem(i.key));

    return deepActiveSubItem !== undefined;
  }

  return hasActiveSubItem(props.item.key);
});

const shouldShowExpandCollapse = computed(() =>
  props.groups.has(props.item.key)
);

const calculatedRoute = computed(() => {
  if (props.item.route) return props.item.route;

  const subItems = props.groups.get(props.item.key);
  const itemWithRoute = subItems?.find((i) => i.route);

  return itemWithRoute?.route;
});

async function onItemClicked(event: MouseEvent) {
  event.preventDefault();
  const item = props.item;

  // Call the items' handler method
  const shouldNavigate = item.handler ? await item.handler(item) : true;

  if (!shouldNavigate) return;

  const route = calculatedRoute.value;
  if (!route) {
    // Developers should return false from the handler
    console.warn(
      `No route defined for navigation item "${item.key}" (or its subitems)`
    );
    return;
  }

  // Execute SPA navigation
  navigateTo(route);
}
</script>

<template>
  <li
    :aid="item.key + '_NAVIGATION_ITEM'"
    class="navigation-item"
    :class="{
      'navigation-item-active': isActive,
      'navigation-item-minimized': isMinimized,
    }"
  >
    <a :href="calculatedRoute" @click="onItemClicked($event)">
      <!-- IMAGE -->
      <NavigationItemIcon
        :font-awesome-icon="item.fontAwesomeIcon"
        :font-awesome-icon-size="item.fontAwesomeIconSize"
        :icon-url="item.iconUrl"
        :icon-url-active="item.iconUrlActive"
        :indent-pixels="indentPixels"
      />

      <!-- TEXT -->
      <div class="item-text">
        {{ item.text }}
      </div>

      <!-- ORNAMENTS -->
      <div class="ornaments">
        <component
          :is="ornament.component"
          v-for="ornament in ornaments"
          :key="ornament.key"
          :aid="ornament.key + '_ORNAMENT'"
        />
      </div>

      <!-- EXPAND subitems -->
      <i
        v-if="!isMinimized && shouldShowExpandCollapse"
        class="expand-collapse fa-regular fa-chevron-down"
      ></i>
    </a>

    <Transition name="slidedown">
      <!-- SUB ITEMS -->
      <div v-if="isExpanded">
        <NavigationGroup :groups :group-key="item.key" :level="level + 1" />
      </div>
    </Transition>
  </li>
</template>

<style scoped lang="scss">
%show-icon {
  > img,
  > span {
    &:first-child {
      display: block;
    }
    &:last-child {
      display: none;
    }
  }
}

%show-active-icon {
  > img,
  > span {
    &:first-child {
      display: none;
    }
    &:last-child {
      display: block;
    }
  }
}

.navigation-item {
  > a {
    height: 36px;
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 8px;
    // TODO: take from CC
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;

    outline: none;
    //transparent border prevents items from jumping
    border: 1px solid transparent;
    border-radius: 50px;

    &:focus-visible {
      border: 1px solid rgb(var(--color-primary-450));
    }

    &:hover {
      background-color: rgb(var(--color-primary-50));
      font-weight: 700;

      :deep(> .navigation-item-icon-container) {
        @extend %show-active-icon;
      }
    }

    :deep(> .navigation-item-icon-container) {
      @extend %show-icon;
    }

    > .item-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > .ornaments {
      display: flex;
      flex-direction: row;
      align-items: center;

      // pushes the ornaments to the right
      margin-left: auto;
    }

    // Animate on opacity
    > .item-text,
    > .ornaments {
      transition: opacity 0.3s ease-out;
    }

    .expand-collapse {
      align-self: center;
      margin-left: auto;
    }
  }
}

// SELECTED
.navigation-item-active {
  > a,
  > a:hover {
    border-radius: 50px;
    background: rgb(var(--color-primary-300));
    // TODO: take from CC
    font-weight: 700;

    :deep(> .navigation-item-icon-container) {
      @extend %show-active-icon;
    }
    > .expand-collapse {
      transform: scaleY(-1);
    }
  }
}

// MINIMAL SIZE
.navigation-item-minimized {
  > a {
    > .item-text,
    > .ornaments {
      opacity: 0;
    }
  }
}

// EXPAND/COLLAPSE TRANSITION
// https://stackoverflow.com/questions/64184203/vuejs-transition-css-only-slide-down
// https://css-tricks.com/using-css-transitions-auto-dimensions/
// TODO: refactor into a transition component - https://vuejs.org/guide/built-ins/transition#reusable-transitions
.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
