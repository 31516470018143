<script setup lang="ts">
import { computed, shallowRef } from 'vue';

import { NavigationBarMode, NavigationComponent } from './api.types';
import { navigationBarMode, navigationComponents } from './api';

const reactiveNavComponents = shallowRef<NavigationComponent[]>([]);
navigationComponents.subscribe((components) => {
  console.debug('core-navigation: components changed', components);
  reactiveNavComponents.value = [...components];
});

const isMinimized = computed(
  () => navigationBarMode.value === NavigationBarMode.MinimalView
);
const isShown = computed(
  () => navigationBarMode.value !== NavigationBarMode.Hidden
);
const canMinimize = computed(
  () => navigationBarMode.value != NavigationBarMode.Locked
);

function toggleViewMode() {
  if (navigationBarMode.value === NavigationBarMode.MinimalView)
    navigationBarMode.value = NavigationBarMode.FullView;
  else navigationBarMode.value = NavigationBarMode.MinimalView;
}
</script>

<template>
  <div
    v-if="isShown"
    class="core-navigation-container"
    :class="{
      'minimized-navigation': isMinimized,
    }"
    aid="NAVIGATION_PANEL"
  >
    <div v-if="canMinimize" class="collapse-expand-button">
      <button @click="toggleViewMode()">
        <i class="fa-regular fa-chevron-left"></i>
      </button>
    </div>

    <!-- DYNAMIC COMPONENTS - menu items are among them -->
    <component
      :is="component.component"
      v-for="component in reactiveNavComponents"
      :key="component.key"
      v-bind="component.props || {}"
      v-on="component.events || {}"
    >
    </component>
  </div>
</template>

<style scoped>
/* https://www.figma.com/design/FOKSeotkMONlj0dFEyNf4S/Design-system?node-id=6008-9215&m=dev */
.core-navigation-container {
  /* changing to auto width (or min-width) entails change to animation */
  width: 228px;
  transition: width 0.3s ease-in-out;

  border-right: 1px solid rgb(var(--color-gray-100));

  display: flex;
  flex-direction: column;
}

.core-navigation-container:hover > .collapse-expand-button {
  display: flex;
}

.minimized-navigation {
  /* four times the padding plus width of largest icon
     this is more than what's defined in figma so icons won't get squashed when in minimized mode */
  width: calc(14px * 4 + 24px);

  .collapse-expand-button button i {
    transform: scaleX(-1);
  }
}

.collapse-expand-button {
  height: 0;
  display: none;
  flex-direction: row-reverse;
  align-items: start;
  top: 20px;
  position: relative;

  button {
    z-index: 1;
    width: 25px;
    height: 25px;
    transform: translate(calc(25px / 2 + 1px), calc(25px / 2));
    background: white;
    border-radius: 50%;

    border: 1px solid #e0e0e0;
    box-shadow:
      0 0 2px rgba(25, 28, 43, 0.04),
      0 4px 8px rgba(107, 108, 126, 0.16);
  }

  button i {
    transition: all 0.75s ease-in-out;
  }
}
</style>
